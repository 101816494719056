import { merge } from "lodash";
import ReportService from "@/services/ReportGeneratorService";

export const state = {
  report: {
    canGenerateReport: false,
  },
};

export const mutations = {
  SET_CAN_GENERATE_REPORT(state, canGenerateReport) {
    state.report.canGenerateReport = canGenerateReport;
  },
};

export const actions = {
  setCanGenerateReport({ commit }, canGenerateReport) {
    commit("SET_CAN_GENERATE_REPORT", canGenerateReport);
  },

  generateReport({ rootState, dispatch }, reportValues) {
    merge(reportValues.parcelDetails, rootState.address.address.currentParcel);
    dispatch("setCanGenerateReport", false);
    ReportService.generateReport(reportValues.parcelDetails, {
      userId: reportValues.userId,
      userEmail: reportValues.userEmail,
      token: reportValues.token,
    });
  },
};
