import Vue from "vue";
import Vuex from "vuex";

import * as user from "@/store/modules/user.js";
import * as address from "@/store/modules/address.js";
import * as report from "@/store/modules/report.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    address,
    report,
  },
  state: {
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    loading: false,
    drawer: false,
    error: "",
  },
  mutations: {
    SET_DRAWER(state, value) {
      state.drawer = value;
    },
    SET_ERROR(state, errorMessage) {
      state.error = errorMessage;
    },
  },
  actions: {
    toggleDrawer({ state, commit }, value) {
      commit("SET_DRAWER", value);
    },
  },
});
