import fetch from "node-fetch";

const GET = "get";

export default {
  fetchSuggestions(address, signal) {
    return (
      fetch(`/api/suggestions?address=${address}`, {
        method: GET,
        signal,
      })
        .then(res => res.json())
        .then(function(json) {
          return {
            items:
              json.AutoCompleteAddressResponse.AutoCompleteAddressResult.string,
            hasNoMatchingAddress: !json.AutoCompleteAddressResponse
              .AutoCompleteAddressResult.string,
          };
        })
        .catch(error => {
          throw error;
        })
    );
  },

  parseSelectedAddress(address) {
    return (
      fetch(`/api/parseAddress?address=${address}`, {
        method: GET,
      })
        .then(res => res.json())
        .then(function(json) {
          const result =
            json.ParseAddressResponse.ParseAddressResult.Results.Result;
          return {
            addressDetails: result.constructor === Array ? result : [result],
          };
        })
        // eslint-disable-next-line no-console
        .catch(error => console.error(error))
    );
  },

  parseLotPlan(lot, plan) {
    return (
      fetch(`/api/parseLotPlan?lot=${lot}&plan=${plan}`, {
        method: GET,
      })
        .then(res => res.json())
        .then(function(json) {
          const resultJson = json.ValidateLotPlanResponse.ValidateLotPlanResult;
          if (resultJson.ResultCount > 0) {
            const result = resultJson.Results.Result;
            return {
              addressDetails: result.constructor === Array ? result : [result],
              hasNoMatchingAddress: false,
            };
          } else {
            return {
              addressDetails: [],
              hasNoMatchingAddress: true,
            };
          }
        })
        // eslint-disable-next-line no-console
        .catch(error => console.error(error))
    );
  },

  fetchPropertyParcelDetails(lot, plan) {
    return (
      fetch(`/api/parcelDetails?lot=${lot}&plan=${plan}`, {
        method: GET,
      })
        .then(res => res.json())
        // eslint-disable-next-line no-console
        .catch(error => console.error(error))
    );
  },
};
