import MapService from "@/services/MapService";
import { merge } from "lodash";

export const state = {
  address: {
    currentParcel: {},
    currentAddressLotPlans: [],
  },
};

export const mutations = {
  SET_ADDITIONAL_PARCEL_FEATURES(state, parcelFeatures) {
    merge(state.address.currentParcel, parcelFeatures);
  },
  SET_CURRENT_ADDRESS_LOTPLANS(state, lotplans) {
    state.address.currentAddressLotPlans = lotplans;
  },
  SET_CURRENT_PARCEL(state, parcel) {
    state.address.currentParcel = parcel;
  },
  CLEAR_ADDRESS_DETAILS(state) {
    state.address = {};
  },
};

export const actions = {
  fetchAddressLotPlans({ commit }, newAddress) {
    return MapService.parseSelectedAddress(newAddress).then(result => {
      const lotplans = result.addressDetails;
      commit("SET_CURRENT_ADDRESS_LOTPLANS", lotplans);
      return lotplans;
    });
  },
  setCurrentAddressLotPlans({ commit }, lotplans) {
    commit("SET_CURRENT_ADDRESS_LOTPLANS", lotplans);
  },
  setCurrentParcel({ commit }, parcel) {
    commit("SET_CURRENT_PARCEL", parcel);
  },
  setAdditionalParcelFeatures({ commit }, parcelFeatures) {
    commit("SET_ADDITIONAL_PARCEL_FEATURES", parcelFeatures);
  },
};
