import Vue from "vue";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { email, max, min, numeric, required } from "vee-validate/dist/rules";

extend("email", { ...email, message: "Please enter a valid email." });
extend("max", max);
extend("min", { ...min, message: "{_field_} should be atleast {length} characters." });
extend("numeric", numeric);
extend("required", { ...required, message: "{_field_} is required." });

Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);