import fetch from "node-fetch";

export default {
  generateReport(address, user) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${user.token}`);
    delete user.token;

    const body = {
      address,
      user,
    };

    return (
      fetch(`/api/generateReport`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: headers,
      })
        .then(res => res.json())
        .then(function(json) {
          // eslint-disable-next-line no-console
          // console.log(json);
        })
        // eslint-disable-next-line no-console
        .catch(error => console.error(error))
    );
  },
};
