import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import crypto from "crypto";

export const state = {
  user: null,
  roles: null,
  subscriptions: null,
  credits: null,
};

export const mutations = {
  SET_ERROR(state, message) {
    alert(message);
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_SUBSCRIPTIONS(state, subscriptions) {
    state.subscriptions = subscriptions;
  },
  SET_CREDITS(state, credits) {
    state.credits = credits;
  },
  LOGOUT(state) {
    state.user = null;
    state.roles = null;
    state.subscriptions = null;
    state.credits = null;
  },
};

const auth = firebase.auth;
const fs = firebase.firestore;

export const actions = {
  async retrieveCredits({ commit }) {
    if (await isUserNotLoggedIn()) {
      // console.log("no user logged in skipping");
      return;
    }
    try {
      // console.log("inside retrieve credits");
      fs()
        .collection("users")
        .doc(auth().currentUser.uid)
        .onSnapshot(async (snapshot) => {
          if (snapshot.empty) {
            commit("SET_CREDITS", null);
            return;
          }
          const userData = snapshot.data();
          if (userData) {
            const credits = userData.remainingCredits;
            commit("SET_CREDITS", credits);
          }
        });
    } catch (error) {
      commit(
        "SET_ERROR",
        `Error encountered in retrieve credits for: ${error}`
      );
    }
  },
  async retrieveSubscriptions({ commit }) {
    if (await isUserNotLoggedIn()) {
      // console.log("no user logged in skipping");
      return;
    }
    try {
      let currentSubscription = null;
      fs()
        .collection("users")
        .doc(auth().currentUser.uid)
        .collection("subscriptions")
        .where("status", "in", ["trialing", "active"])
        .onSnapshot(async (snapshot) => {
          // this.overlay = true;
          if (snapshot.empty) {
            commit("SET_SUBSCRIPTIONS", null);
            // this.overlay = false;
            return;
          }
          // In this implementation we only expect one Subscription to exist
          const subscription = snapshot.docs[0].data();
          const productData = (await subscription.product.get()).data();
          const priceData = (await subscription.price.get()).data();
          currentSubscription = `${new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: priceData.currency,
          }).format((priceData.unit_amount / 100).toFixed(2))} per ${
            priceData.interval
          } on our ${productData.name} plan`;
          // // console.log("inside retrieve subscriptions -> set to "+currentSubscription)
          // const subsData = {
          //   display: currentSubscription,
          //   unit_amount: (priceData.unit_amount / 100).toFixed(2),
          //   interval: priceData.interval,
          //   plan: productData.name
          // }
          commit("SET_SUBSCRIPTIONS", currentSubscription);
        });
    } catch (error) {
      // // console.log("inside retrieve subscriptions -> error occurred")
      commit(
        "SET_ERROR",
        `Error encountered in retrieve subscription for: ${error}`
      );
    }
  },
  async signup({ dispatch, commit }, user) {
    return auth()
      .setPersistence(auth.Auth.Persistence.SESSION)
      .then(async () => {
        try {
          const userCredentials = await auth().createUserWithEmailAndPassword(
            user.email,
            user.password
          );

          if (user.name !== null && user.email != null) {
            const hashedEmail = crypto
              .createHash("md5")
              .update(user.email)
              .digest("hex");
            await userCredentials.user.updateProfile({
              displayName: user.name,
              photoURL: `https://www.gravatar.com/avatar/${hashedEmail}.jpg?r=g&d=mp`,
            });

            commit("SET_USER", userCredentials.user);
            commit("SET_ROLES", await getUserRoles());
            commit("SET_DRAWER", true);

            var uid = auth().currentUser.uid;
            await setOnlinePresence(uid);

            return await dispatch("retrieveSubscriptions");
          }
        } catch (error) {
          commit(
            "SET_ERROR",
            `Error encountered in first sign up for ${user.email} : ${error}`
          );
        }
      });
  },
  async login({ dispatch, commit }, user) {
    return auth()
      .setPersistence(auth.Auth.Persistence.SESSION)
      .then(async () => {
        try {
          const userCredentials = await auth().signInWithEmailAndPassword(
            user.email,
            user.password
          );

          var uid = auth().currentUser.uid;
          if (await isOnline(uid)) {
            commit(
              "SET_ERROR",
              `User with email ${user.email} is already logged in.`
            );
            await dispatch("logout");
            return;
          }

          commit("SET_USER", userCredentials.user);
          commit("SET_ROLES", await getUserRoles());
          commit("SET_DRAWER", true);
          await dispatch("retrieveSubscriptions");
          await setOnlinePresence(uid);
          return userCredentials;
        } catch (error) {
          commit(
            "SET_ERROR",
            `Error encountered in logging in for ${user.email} : ${error}`
          );
          throw error;
        }
      });
  },
  async logout({ commit }) {
    await setOfflinePresence(auth().currentUser.uid);
    await auth().signOut();
    commit("LOGOUT");
  },
  async forgot({ commit }, user) {
    // forgot password so reset it
    try {
      return auth()
        .sendPasswordResetEmail(user.email, {
          url: window.location.origin + "/#/pages/login",
        })
        .then(() => {
          commit("LOGOUT");
        });
    } catch (error) {
      commit(
        "SET_ERROR",
        `Error encountered in resetting password in for ${user.email} : ${error}`
      );
    }
  },
  async checkAuth({ commit }) {
    if (auth().currentUser) {
      commit("SET_USER", auth().currentUser);
      commit("SET_ROLES", await getUserRoles());
    }
  },
  async refreshUser({ dispatch, commit }, user) {
    if (user) {
      commit("SET_USER", user);
      setOnlinePresence(user.uid);
      // console.log("refreshing id token for claims");
      commit("SET_ROLES", await getUserRoles());
      return await dispatch("retrieveSubscriptions");
    }
  },
  async setUserOnlinePresence() {
    const user = auth().currentUser;
    if (user.uid != null) {
      setOnlinePresence(user.uid);
    }
  },
};

// private functions

async function isUserNotLoggedIn() {
  return (
    auth() === undefined ||
    auth().currentUser === undefined ||
    auth().currentUser?.uid === undefined ||
    auth().currentUser?.uid == null
  );
}

async function getUserRoles() {
  let result = null;
  const idTokenResult = await auth().currentUser.getIdTokenResult();
  if (idTokenResult.claims.stripeRole) {
    result =
      idTokenResult.claims.stripeRole === null
        ? "None"
        : idTokenResult.claims.stripeRole;
    // console.log(`about to call setroles with ${result}`);
  }
  return result;
}

function getUserStatusDbRef(userId) {
  return firebase.database().ref(`/status/${userId}`);
}

async function isOnline(userId) {
  const userStatus = await getUserStatusDbRef(userId).get();
  if (userStatus.exists) {
    return userStatus.val().state === "online";
  }
  return false;
}

async function setOnlinePresence(userId) {
  var userStatusDbRef = getUserStatusDbRef(userId);

  var isOfflineForDatabase = {
    state: "offline",
    last_changed: firebase.database.ServerValue.TIMESTAMP,
  };

  var isOnlineForDatabase = {
    state: "online",
    last_changed: firebase.database.ServerValue.TIMESTAMP,
  };

  userStatusDbRef
    .onDisconnect()
    .set(isOfflineForDatabase)
    .then(function () {
      // The promise returned from .onDisconnect().set() will
      // resolve as soon as the server acknowledges the onDisconnect()
      // request, NOT once we've actually disconnected:
      // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

      // We can now safely set ourselves as 'online' knowing that the
      // server will mark us as offline once we lose connection.
      userStatusDbRef.set(isOnlineForDatabase);
    });
}

async function setOfflinePresence(userId) {
  var userStatusDbRef = getUserStatusDbRef(userId);

  var isOfflineForDatabase = {
    state: "offline",
    last_changed: firebase.database.ServerValue.TIMESTAMP,
  };

  userStatusDbRef.set(isOfflineForDatabase);
}
