// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
import Vue from "vue";
import App from "./App.vue";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/database";
import "firebase/auth";
import "firebase/analytics";
import firebaseConfig from "../functions/src/config/FirebaseConfig.json";
import firebaseConfigDev from "../functions/src/config/FirebaseConfig-develop.json";
import router from "./router";
import store from "./store/store";
import "./plugins/base";
import "./plugins/chartist";
import "./plugins/vee-validate";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import "@arcgis/core/assets/esri/themes/light-blue/main.css";

import { firestorePlugin } from "vuefire";

// const projectId = process.env.GCP_PROJECT;
if (process.env.VUE_APP_ENV_NAME === "develop") {
  firebase.initializeApp(firebaseConfigDev);
} else {
  firebase.initializeApp(firebaseConfig);
}
firebase.analytics();


const storage = firebase.storage();
const rdb = firebase.database();
const db = firebase.firestore();
const functions = firebase.functions();

if (
  process.env.VUE_APP_USE_LOCAL_EMULATORS === "true" &&
  location.hostname === "localhost"
) {
  if (process.env.VUE_APP_USE_AUTH_EMULATOR === "true") {
    // console.log("SETTING UP FIREBASE TO USE LOCAL EMULATOR FOR AUTH");
    const auth = firebase.auth();
    auth.useEmulator("http://localhost:9099");
  }

  // console.log("SETTING UP FIREBASE TO USE LOCAL EMULATOR FOR FIRESTORE");
  db.useEmulator("localhost", 9090);
  // console.log("SETTING UP FIREBASE TO USE LOCAL EMULATOR FOR STORAGE");
  rdb.useEmulator("localhost", 9000);
  // console.log("SETTING UP FIREBASE TO USE LOCAL EMULATOR FOR STORAGE");
  storage.useEmulator("localhost", 9199);
  // console.log("SETTING UP FIREBASE TO USE LOCAL EMULATOR FOR FUNCTIONS");
  functions.useEmulator("localhost", 5001);
}

Vue.use(firestorePlugin);
Vue.config.productionTip = false;

let app;

firebase.auth().onIdTokenChanged((user) => {
  store
    .dispatch("refreshUser", user, { root: true });
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
