import Vue from "vue";
import Router from "vue-router";
import firebase from "firebase/app";
import "firebase/auth";
import store from "./store/store";

Vue.use(Router);

const router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import("@/views/dashboard/Index"),
      children: [
        // Property Intel URLs
        {
          name: "Sign Up",
          path: "pages/signup",
          component: () => import("@/views/dashboard/pages/Signup"),
          meta: {
            requiresGuest: true
          }
        },
        {
          name: "Login",
          path: "pages/login",
          component: () => import("@/views/dashboard/pages/Login"),
          meta: {
            requiresGuest: true
          }
        },
        {
          name: "Forgot",
          path: "pages/forgot",
          component: () => import("@/views/dashboard/pages/Forgot"),
          meta: {
            requiresGuest: true
          }
        },
        {
          name: "Address Search Bar",
          path: "pages/search-bar",
          component: () => import("@/views/dashboard/pages/SearchBar"),
          alias: "pages/search-bar"
        },        
        {
          name: "Property Search",
          path: "/",
          component: () => import("@/views/dashboard/pages/Search"),
          alias: "pages/search"
        },
        {
          name: "Dashboard",
          path: "pages/user-dashboard",
          component: () => import("@/views/dashboard/UserDashboard"),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "My Account",
          path: "pages/user",
          component: () => import("@/views/dashboard/pages/UserProfile"),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "Subscribe",
          path: "pages/subscribe",
          component: () => import("@/views/dashboard/pages/Subscription"),
        },
        {
          name: "Resources",
          path: "pages/resources",
          component: () => import("@/views/dashboard/pages/Resources"),
          meta: {
            requiresAuth: true
          }
        },
        // // Material Dashboard
        // {
        //   name: "MaterialDashboard",
        //   path: "pages/dashboard",
        //   component: () => import("@/views/dashboard/Dashboard"),
        // },
        // // Pages
        // {
        //   name: "Timeline",
        //   path: "pages/timeline",
        //   component: () => import("@/views/dashboard/pages/Timeline")
        // },
        // {
        //   name: "Notifications",
        //   path: "components/notifications",
        //   component: () => import("@/views/dashboard/component/Notifications")
        // },
        // {
        //   name: "Icons",
        //   path: "components/icons",
        //   component: () => import("@/views/dashboard/component/Icons")
        // },
        // {
        //   name: "Typography",
        //   path: "components/typography",
        //   component: () => import("@/views/dashboard/component/Typography")
        // },
        // // Tables
        // {
        //   name: "Regular Tables",
        //   path: "tables/regular-tables",
        //   component: () => import("@/views/dashboard/tables/RegularTables")
        // },
        // // Maps
        // {
        //   name: "Google Maps",
        //   path: "maps/google-maps",
        //   component: () => import("@/views/dashboard/maps/GoogleMaps")
        // },
        // // Upgrade
        // {
        //   name: "Upgrade",
        //   path: "upgrade",
        //   component: () => import("@/views/dashboard/Upgrade")
        // }
      ]
    }
  ]
});

router.beforeEach(async (to, from, next) => {

  // if still in soft launch mode hide all pages
  if (to.matched.some(record => record.meta.requiresAuth) 
    && process.env.VUE_APP_SOFTLAUNCH_MODE === "true") {
    next({
      path: "/",
      query: {
        redirect: to.fullPath
      }
    });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // if not logged in
    if (!firebase.auth().currentUser) {
      next({
        path: "/",
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      await store.dispatch("setUserOnlinePresence");
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    if (firebase.auth().currentUser) {
      next({
        path: "/",
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
