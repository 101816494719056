<template>
  <base-material-card
    :color="(index === 0 && tabtype === 0 && product.role === 'standard' || index === 1 && tabtype === 1) ? 'green' : 
    (index === 1 && tabtype === 0 || product.role === 'enterprise' || product.role === 'professional' )
        ? 'blue'
        : 'light-blue'
    "
    class="px-5 py-3"
    :image="false"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:heading>
      <div class="float-right">
        <div class="text-h4 text-right">
          {{ product.name }}
        </div>

        <div class="text-h2 text-right">
          {{
            (product.role === 'enterprise')?"Contact Us":
              new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: price.currency,
            }).format((price.unit_amount / 100).toFixed(2))
          }}
        </div>

        <div class="text-subtitle-1 text-right">
          {{
            (product.role === 'enterprise')?"Licensing Options": 
            price.interval ? `per ${price.interval}` : "per report"
          }}
        </div>
      </div>
    </template>

    <v-card-title style="overflow-wrap: break-word;">
      {{
        product.role === "oneoff"
          ? "Purchase a Single PropCheck Property Report"
          : `PropCheck Property Report ${product.name} Subscription`
      }}
    </v-card-title>

    <v-card-text>
      <v-card-text v-if="price.metadata !== undefined && price.metadata.maxReports !== undefined">
        Business Subscription for {{price.metadata.maxReports}} reports a {{price.interval}} 
      </v-card-text>
      <!-- <v-card-text v-if="product.role === 'business' && price.interval === 'month'">
        Business Subscription for {{price.metadata.maxReports}} reports a {{price.interval}} 
      </v-card-text>
      <v-card-text v-else-if="product.role === 'business' && price.interval === 'year'">
        Business Subscription for {{price.metadata.maxReports}} reports a {{price.interval}}
      </v-card-text> -->
      <v-card-text v-else>
        {{ product.description }}
      </v-card-text>
      <v-spacer/>
      <v-card-text>
        Access to quality property reports to ensure you've done your due dilligence on
        your next property investment including the following features:
      </v-card-text>

      <v-spacer></v-spacer>

      <v-list>
        <v-list-item v-show="product.role !== 'oneoff'">
          <v-list-item-icon>
            <v-icon color="green">
              mdi-check-circle
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              <v-tooltip v-if="product.role !== 'business'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span class="font-weight-bold" v-bind="attrs" v-on="on"
                    >Generate {{product.role === 'standard'?"Up to 5":product.role === 'professional'?"Up to 10":"Unlimited"}} Reports</span
                  >
                </template>
                <span>Generate {{product.role === 'standard'?"Up to 5":product.role === 'professional'?"Up to 10":"Unlimited"}} Reports for different property locations</span>
              </v-tooltip>
              <v-tooltip v-if="product.role === 'business'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span class="font-weight-bold" v-bind="attrs" v-on="on"
                    >Generate Up to {{price.metadata.maxReports}} Reports a {{price.interval}} 
                  </span>
                </template>
                <span>Generate Up to {{price.metadata.maxReports}} Reports a {{price.interval}} for different property locations</span>
              </v-tooltip>              
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="product.role === 'oneoff'">
          <v-list-item-icon>
            <v-icon color="green">
              mdi-check-circle
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              >Generate a Single Report</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="product.role !== 'oneoff'">
          <v-list-item-icon>
            <v-icon color="green">
              mdi-check-circle
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span class="font-weight-bold" v-bind="attrs" v-on="on"
                    >{{product.role === 'standard'?"Up to 5":product.role === 'professional'?"Up to 10":"Unlimited"}} generated report storage</span
                  >
                </template>
                <span
                  >Download previously generated reports as many times as you
                  like</span
                >
              </v-tooltip>
            </v-list-item-title>
            <!-- <v-list-item-subtitle>Personal</v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="green">
              mdi-check-circle
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              >Sign Up for Free </v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="green">
              mdi-check-circle
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :class="product.role === 'enterprise'?'font-weight-bold':'font-weight-normal'"
              >{{product.role !== 'enterprise'?"Single User Access":"Multiple User Options"}}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="product.role !== 'oneoff'">
          <v-list-item-icon>
            <v-icon color="green">
              mdi-check-circle
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              >View Previously Purchased Reports </v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="product.role !== 'oneoff'">
          <v-list-item-icon>
            <v-icon color="green">
              mdi-check-circle
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              >Customize User Profile</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="product.role !== 'oneoff'">
          <v-list-item-icon>
            <v-icon color="green">
              mdi-check-circle
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              >Access to PropCheck Resources</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="product.role !== 'oneoff'">
          <v-list-item-icon>
            <v-icon color="green">
              mdi-check-circle
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              >Access to latest feature updates as their
              released</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="product.role !== 'oneoff'">
          <v-list-item-icon>
            <v-icon color="green">
              mdi-check-circle
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :class="product.role === 'standard'?'font-weight-normal':'font-weight-bold'">
              {{product.role === 'standard'?"Standard":"Premium"}} Local Support Contact</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="product.role === 'oneoff'">
          <v-list-item-content>
            <v-list-item-title></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="product.role === 'oneoff'">
          <v-list-item-content>
            <v-list-item-title></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="product.role === 'oneoff'">
          <v-list-item-content>
            <v-list-item-title></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="product.role === 'oneoff'">
          <v-list-item-content>
            <v-list-item-title></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="product.role === 'oneoff'">
          <v-list-item-content>
            <v-list-item-title></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="product.role === 'oneoff'">
          <v-list-item-content>
            <v-list-item-title></v-list-item-title>
          </v-list-item-content>
        </v-list-item>                                        

      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :loading="btnloading"
        :disabled="btnloading"
        :color="(index === 0 && tabtype === 0 && product.role === 'standard' || index === 1 && tabtype === 1) ? 'green':'primary'"
        @click="reserve(price.id)"
      >
        {{ product.role === 'enterprise' ? "Contact Us" : (price.type === "recurring") ? "Subscribe" : "Purchase" }}
      </v-btn>
    </v-card-actions>
  </base-material-card>
</template>
<script>

export default {
  name: "MaterialPricingCard",

  inheritAttrs: false,

  props: {
    index: {
      type: Number,
      default: 0,
    },
    product: {
      type: Object,
      default: () => ({}),
    },
    price: {
      type: Object,
      default: () => ({}),
    },
    btnloading: {
      type: Boolean,
      default: false,
    },
    btnloader: {
      type: Object,
      default: () => ({}),
    },    
    tabtype: {
      type: Number,
      default: 0
    },
    currentUser: {
      type: Object,
      default: null
    },
  },

  mounted() {
    // Instead of calling the method we emit an event
    // this.$emit('send-message', this.value);
  },

  methods: {
    reserve(aPriceId) {
      // find the price matching the user selction (yearly or monthly?)
      const price = this.product.prices.find((price) => price.id === aPriceId);
      // alert(`sending emit PRICE send-message for ${price.id} (${price.unit_amount}) on product ${this.product.name}`);
      this.$emit('do-subscription', this.product, price);
    },
  }
}
</script>
<style scoped>
/* your css */

.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}

</style>
